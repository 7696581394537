import React from 'react';
import './Nav.scss'
import { slide as Menu } from 'react-burger-menu'
import { Link } from 'react-scroll'
// import {Link} from 'react-router-dom';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import logo from '../../Asset/Logo/combined_shape_copy_3.svg'

class Nav extends React.Component {
    state = {
        menuOpen: false
    }
    handleStateChange (state) {
        this.setState({menuOpen: state.isOpen})  
    }

    closeMenu = () => {
        this.setState({menuOpen: false})
    }

    toggleMenu () {
        this.setState(state => ({menuOpen: !state.menuOpen}))
    }

    render (){ 
        return (
            <nav id="nav-section">
                <div className="mobile">
                    <div className="nav">
                        <div className="nav__menu-container">
                            {/* <img src={menu} className="nav__menu-container__img"></img> */}
                        </div>
                        <div className="nav__logo-container">
                            <img src={logo} className="nav__logo-container__img"></img>
                        </div>
                    </div>
                    <Menu width={'65%'} isOpen={this.state.menuOpen} onStateChange={(state) => this.handleStateChange(state)}>
                        <div className="nav__menu__logo" >
                            <img src={logo} alt="logo" onClick={() => this.closeMenu()}></img>
                        </div>
                        <Link onClick={() => this.closeMenu()} to="skill-section" smooth={true} duration={1000}><NavLink to="/" className="navlink">About</NavLink></Link>
                        <Link onClick={() => this.closeMenu()} to="projects-section" smooth={true} duration={1000}><NavLink to="/" className="navlink">Work</NavLink></Link>
                        <Link onClick={() => this.closeMenu()} to="contact-section" smooth={true} duration={1000}><NavLink to="/" className="navlink">Contact</NavLink></Link>
                    </Menu>
                </div>
                <div className="tablet-desktop">
                    <div className="tablet-desktop__nav">
                        <img src={logo} className="tablet-desktop__img"></img>
                        <ul className="tablet-desktop__bottom">
                        <Link onClick={() => this.closeMenu()} to="skill-section" smooth={true} duration={1000}><li className="tablet-desktop__bottom__item">About</li></Link>
                        <Link onClick={() => this.closeMenu()} to="projects-section" smooth={true} duration={1000}><li className="tablet-desktop__bottom__item">Work</li></Link>
                        <Link onClick={() => this.closeMenu()} to="contact-section" smooth={true} duration={1000}><li className="tablet-desktop__bottom__item">Contact</li></Link>
                        </ul>
                    </div>
                </div>
            </nav>
        )
    }
}

export default Nav