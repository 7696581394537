import React from 'react';
import './Main.scss'
import Hero from '../../Components/Hero/Hero'
import Skill from '../../Components/Skills/Skill'
import Projects from '../../Components/Projects/Projects'
import Contact from '../../Components/Contact/Contact'
import Footer from '../../Components/Footer/Footer'

class Main extends React.Component {
    render (){ 
        return (
            <div>
                <Hero/>
                <Skill/>
                <Projects/>
                <Contact/>
                <Footer/>
            </div>
        )
    }
}

export default Main