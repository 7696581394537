import React from 'react';
import './Projects.scss'
import fetch from '../../Asset/Image/Fetch-img/group_7_copy.png';
import match from '../../Asset/Image/Match-Maker/group_8.png';
// import Qportfolio from '../../Asset/Image/QPortfolio/qcombined2.png';
import JodiPortfolio from '../../Asset/Image/JodiPortfolio/Group 68.svg'


class Projects extends React.Component {
    render (){
        return (
            
            <div id="projects-section">
                <h3 className="recent-work__title">My Recent Works</h3>
                <section className="projects">
                    <h3 className="projects__title">Fetch</h3>
                    <div className="projects__fetch"></div>
                    <p className="projects__description">Fetch is a mobile app that using Google News API to aggregator news articles for the user based on search categories or general topics. Secondary feature is it allows users to access the article while internet access is unable, this will preload the news article from the website and store the content locally until user needs it.</p>
                    <a href="http://fetch-news-app.herokuapp.com/" className="projects__link">View My Work</a>
                </section>
                <section className="desktop">
                    <div className="desktop__container">
                        <h3 className="desktop__container__title">Fetch</h3>
                        <p className="desktop__container__description">Fetch is a mobile app that using Google News API to aggregator news articles for the user based on search categories or general topics. Secondary feature is it allows users to access the article while internet access is unable, this will preload the news article from the website and store the content locally until user needs it.</p>
                        <a href="http://fetch-news-app.herokuapp.com/" className="desktop__container__link">View My Work</a>
                    </div>
                    <div><img src={fetch} className="desktop__fetch"></img></div>
                </section>
                <section className="projects">
                    <h3 className="projects__title">Match-Maker</h3>
                    <div className="projects__match"></div>
                    <p className="projects__description">An peer to peer challenge where we were tasked to come up with an idea and excute within 8 hours. My teammate and I decide to create a simple matching game using javascript and some animation.</p>
                    <a href="https://match-maker-hackathon.netlify.com" className="projects__link">View My Work</a>
                </section>
                <section className="desktop">
                    <div><img src={match} className="desktop__match"></img></div>
                    <div className="desktop__container__right">
                        <h3 className="desktop__container__title">Match-Maker</h3>
                        <p className="desktop__container__description">An peer to peer challenge where we were tasked to come up with an idea and excute within 8 hours. My teammate and I decide to create a simple matching game using javascript and some animation.</p>
                        <a href="https://match-maker-hackathon.netlify.com" className="desktop__container__link">View My Work</a>
                    </div>
                </section>
                <section className="projects">
                    <h3 className="projects__title">Portfolio</h3>
                    <div className="projects__qportfolio"></div>
                    <p className="projects__description">A portfolio site that a client commissioned me to build to demonstrate their machine learning projects that they created. </p>
                    <a href="https://jodi-portfolio.netlify.app/" className="projects__link">View My Work</a>
                </section>
                <section className="desktop">
                    <div className="desktop__portfolio">
                        <h3 className="desktop__container__title">Portfolio</h3>
                        <p className="desktop__container__description">A portfolio site that a client commissioned me to build to demonstrate their machine learning projects that they created. </p>
                        <a href="https://jodi-portfolio.netlify.app/" className="desktop__container__link">View My Work</a>
                    </div>
                    <div><img src={JodiPortfolio} className="desktop__qportfolio"></img></div>
                </section>
            </div>
        )
    }
}

export default Projects