import React from 'react';
import './Skill.scss'
import photo from '../../Asset/Image/Portfolio/group_11.png'

class Skill extends React.Component {
    render (){ 
        return (
            <div id="skill-section">
                <h3 className="font__title">A Little About Me</h3>
                <div className="icons">
                    <section className="skill">
                        <article className="skill__icon skill__html"></article>
                        <p className="skill__icon__text font">HTML5</p>
                    </section>
                    <section className="skill">
                        <article className="skill__icon skill__css"></article>
                        <p className="skill__icon__text font">CSS3</p>
                    </section>
                    <section className="skill">
                        <article className="skill__icon skill__js"></article>
                        <p className="skill__icon__text font">JavaScript</p>
                    </section>
                    <section className="skill">
                        <article className="skill__icon skill__typescript"></article>
                        <p className="skill__icon__text font">TypeScript</p>
                    </section>
                    <section className="skill">
                        <article className="skill__icon skill__react"></article>
                        <p className="skill__icon__text font">React</p>
                    </section>
                    <section className="skill">
                        <article className="skill__icon skill__express"></article>
                        <p className="skill__icon__text font">Express</p>
                    </section>
                </div>
                <div className="about">
                    <div className="about__container">
                        <div className="about__img">
                            <img src={photo} className="about__img__photo"></img>
                        </div>
                        <div className="about__text-container">
                            <h3 className="about__title">Who am I?</h3>
                            <p className="about__content">I'm a web developer based in Toronto. I have always dream about new and useful things that the world would like to see and find a way to bring it to life.  </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Skill