import React from 'react';
import {Link} from 'react-scroll'
import './Hero.scss'


class Hero extends React.Component {
    render (){ 
        return (
            <section className="main">
                <div className="main__hero">
                    <div className="main__header">
                        <p>Hello, I'm Ivan</p>
                        <p className="main__header__bottom">I'm a web developer</p>
                        <Link className="main__button" to="projects-section" smooth={true} duration={1000}>View My Work</Link>
                    </div>
                </div>
            </section>
        )
    }
}

export default Hero