import React from 'react';
import Modal from 'react-modal';
import './Contact.scss';
import SVG from '../../Asset/Image/Portfolio/diagonalline.svg';
import emailjs from 'emailjs-com';
import GreenCheck from '../../Asset/Icon/greenCheckMark.svg'

const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      width                 : '250px',
      height                : '180px',
      textAlign             : 'center',
      display               : 'flex',
      flexDirection         : 'column',
      justifyContent        : 'center',
      alignItems             : 'center',
    }
};

class Contact extends React.Component {
    state={
        name: '',
        email: '',
        message: '',
        nameErrorState: false,
        emailErrorState: false,
        messageErrorState: false,
        modalIsOpen: false,
        serverError: false,
    }

    sendEmail = (e) => {
        e.preventDefault();
        this.reset()

        if(this.state.name && !this.state.email && !this.state.message){
            this.setState({
                nameErrorState: false,
                emailErrorState: true,
                messageErrorState: true,
            });
        } else if(!this.state.name && this.state.email && !this.state.message ){
            this.setState({
                nameErrorState: true,
                emailErrorState: false,
                messageErrorState: true,
            });
        } else if(!this.state.name && !this.state.email && this.state.message ){
            this.setState({
                nameErrorState: true,
                emailErrorState: true,
                messageErrorState: false,
            });
        } else if(!this.state.name && this.state.email && this.state.message){
            this.setState({
                nameErrorState: true,
                emailErrorState: false,
                messageErrorState: false,
            });
        } else if(this.state.name && !this.state.email && this.state.message){
            this.setState({
                nameErrorState: false,
                emailErrorState: true,
                messageErrorState: false,
            });
        } else if(this.state.name && this.state.email && !this.state.message){
            this.setState({
                nameErrorState: false,
                emailErrorState: false,
                messageErrorState: true,
            });
        } else if(!this.state.name && !this.state.email && !this.state.message){
            this.setState({
                nameErrorState: true,
                emailErrorState: true,
                messageErrorState: true,
            });
        } else if(this.state.name && this.state.email && this.state.message){
            let templateParam = {
                'name': this.state.name, 
                'email': this.state.email, 
                'message': this.state.message, 
            }
            emailjs.send(`${process.env.REACT_APP_EMAILJS_SERVICE_ID}`, `${process.env.REACT_APP_EMAILJS_TEMPLATE_ID}`, templateParam, `${process.env.REACT_APP_EMAILJS_USERID}`)
            .then((result) => {
                this.setState({
                    modalIsOpen: true,
                });
            }, (error) => {
                this.setState({
                    serverError: true,
                });
            });
            e.target.reset();
        }      
    }

    reset = () => {
        this.setState({
            nameErrorState: false,
            emailErrorState: false,
            messageErrorState: false,
        });
    }

    closeModal = () => {
        this.setState({
            modalIsOpen: false,
        });
    }

    render (){
        return (
            <div className="contact" id="contact-section">
                <img src={SVG} className="contact__SVG"></img>
                <h3 className="contact__title">Contact</h3>
                <p className="contact__description">Have a questions or want to work together?</p>
                <form className="contact__form" onSubmit={this.sendEmail}>
                    <input type="hidden" name="form-name" value="contact"/>
                    <input type="text" name="name" className={this.state.nameErrorState === true ? "contact__form__nameError" : "contact__form__name"} placeholder="Your Name" onChange={(e)=>{this.setState({name: e.target.value})}}></input>
                    <input type="email" name="email" className={this.state.emailErrorState === true ? "contact__form__emailError" : "contact__form__email"} placeholder="Your Email Address" onChange={(e)=>{this.setState({email: e.target.value})}}></input>
                    <textarea name="message" id="message" className={this.state.messageErrorState === true ? "contact__form__messageError" : "contact__form__message"} placeholder="Your Message" onChange={(e)=>{this.setState({message: e.target.value})}}></textarea>
                    <button type="submit" value="SendMessage" className="contact__submit-btn">Submit</button>
                </form> 
                <Modal
                isOpen={this.state.modalIsOpen}
                onRequestClose={this.closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                >
                    <img alt='Success icon' className='check' src={GreenCheck}/>
                    <p>You have successfully sent the message to Ivan.</p>
                    <button className='btn' onClick={this.closeModal}>Return to Contact</button>
                </Modal>
            </div>
        )
    }
}

export default Contact