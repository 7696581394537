import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import Main from './Pages/MainPage/Main'
import Nav from './Components/Nav/Nav'
import CV from './Components/Resume/CV'

import './App.scss';

class App extends React.Component {

  render (){
    return (
      <BrowserRouter>
        <Nav/>
        <Switch>
          <Route path="/" exact component={Main}/>
          <Route path="/resume" component={CV}/>
        </Switch>
      </BrowserRouter> 
    )
  }
  
}

export default App;
