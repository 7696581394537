import React from 'react';
import './Footer.scss'



class Footer extends React.Component {
    render (){
        return (
            <div className="footer">
                <div className="footer__flex">
                    <section className="footer__container">
                        <a href="https://www.linkedin.com/in/ivanchautf/">
                            <div className="footer__container__linkedin space">

                            </div>
                        </a>
                    </section>
                    <section className="footer__container">
                        <a href="https://github.com/Ivan1023">
                            <div className="footer__container__github space">

                            </div>
                        </a>
                    </section>
                </div>
                <p className="footer__copyright">&copy; 2020 ivanchau.com</p>
            </div>
        )
    }
}

export default Footer